export function renderApp({ appName, canRender, props }: { appName: string; canRender: boolean; props?: string }) {
  if (canRender) return `<application name="${appName}" ${props !== undefined ? `props="${props}"` : ''}></application>`
  return ''
}

export function renderAppWithRoute({
  appName,
  path,
  canRender,
  props,
  exactPage
}: {
  appName: string
  path: string
  canRender: boolean
  props?: string
  exactPage?: boolean
}) {
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (!path) {
    throw new Error('Path parameter is required')
  }

  if (!canRender) {
    return ''
  }

  return `
    <route path="${path}" ${exactPage !== undefined ? 'exact' : ''}>
      <application name="${appName}" ${props !== undefined ? `props="${props}"` : ''}></application>
    </route>
  `
}
