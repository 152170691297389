import { authorizationHelper } from '../../initializeServices'
import { renderAppWithRoute } from '../renderApp'

const journeyName = 'ci'

const redirectFromPathsTo = (paths, to) =>
  paths.map((path) => `<redirect from="${path}" to="${to}"></redirect>`).join('')

function entryCiRoute() {
  const hasAttendanceFeature = authorizationHelper.canAccessFeature({ featureName: 'view-attendance', journeyName })
  const hasControlFeature = authorizationHelper.canAccessFeature({ featureName: 'can-segmentation-base', journeyName })
  const hasChecklistCreateFeature = authorizationHelper.canAccessFeature({
    featureName: 'create-checklist',
    journeyName
  })

  switch (true) {
    // Não redirecionar se o usuário possuir a feature 'view-home-page'
    // @ts-expect-error Adicionar a feature na biblioteca de tipos @grupoboticario/vdi-mfe-definition-types
    case authorizationHelper.canAccessFeature({ featureName: 'view-home-page' }):
      return ''
    case hasChecklistCreateFeature:
      return redirectFromPathsTo(['/', '/inicios'], '/inicios/centrais-de-servico')
    case hasAttendanceFeature:
      return redirectFromPathsTo(['/', '/inicios'], '/inicios/atendimento')
    case hasControlFeature:
      return redirectFromPathsTo(['/', '/inicios'], '/inicios/mailing/control/distribuicao-irs')
    default:
      return ''
  }
}

export function renderCiRoutes() {
  return `
      ${entryCiRoute()}

      ${renderAppWithRoute({
        appName: '@vdi/atendimento',
        path: '/inicios/atendimento',
        canRender: authorizationHelper.canAccessFeature({
          featureName: 'view-attendance',
          journeyName
        }),
        props: 'user'
      })}

      ${renderAppWithRoute({
        appName: '@vdi/service-center',
        path: '/inicios/centrais-de-servico',
        canRender: authorizationHelper.canAccessFeature({
          featureName: 'create-checklist',
          journeyName
        })
      })}

      ${renderAppWithRoute({
        appName: '@vdi/mailing',
        path: '/inicios/mailing',
        canRender:
          authorizationHelper.canAccessFeature({
            featureName: 'view-mailing',
            journeyName
          }) ||
          authorizationHelper.canAccessFeature({
            featureName: 'can-segmentation-base',
            journeyName
          })
      })}

      ${renderAppWithRoute({
        appName: '@vdi/ci-logs',
        path: '/inicios/logs',
        canRender: authorizationHelper.canAccessFeature({
          featureName: 'view-logs',
          journeyName
        })
      })}

      ${renderAppWithRoute({
        appName: '@vdi/pre-ativacao-mfe-admin',
        path: '/inicios/admin',
        canRender: authorizationHelper.canAccessFeature({
          featureName: 'view-admin',
          journeyName
        })
      })}
    `
}
