import { registerApplication, start } from 'single-spa'
import { constructApplications, constructLayoutEngine } from 'single-spa-layout'
import '@grupoboticario/flora-screenrecording-addon'

import { type IAuthService, authService } from '@vdi/auth-service'

import { factoryUserRoutes } from './utils/routes/factoryUserRoutes'
import { initializeServices } from './initializeServices'
import { monitoringClient } from './utils/monitoring'
import { sendUserDataToGa } from './utils/analytics/sendUserDataToGa'

function initRootApp(authService: IAuthService) {
  const layoutData = {
    loaders: {},
    props: {
      user: authService.userData
    }
  }
  const routes = factoryUserRoutes({ layoutData })

  const applications = constructApplications({
    routes,
    loadApp({ name }) {
      return System.import(name)
    }
  })
  const layoutEngine = constructLayoutEngine({ routes, applications })

  applications.forEach(registerApplication)
  layoutEngine.activate()
}

initializeServices(sendUserDataToGa, initRootApp)
  .then(() => {
    start({
      urlRerouteOnly: true
    })
    setTimeout(() => {
      const notFoundPageWrapper = document.querySelector('.page-not-found')
      if (notFoundPageWrapper !== null) {
        ;(notFoundPageWrapper as HTMLDivElement).style.display = 'flex'
      }
    }, 3000)

    return null
  })
  .catch((error) => {
    monitoringClient.error('Error initializing services', { error, id: authService.userData.id })
    console.error('Error initializing services')
  })
