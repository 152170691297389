import { GtmTracker } from '@vdi/event-tracker'
import { userTypeByEmail } from './userTypeByEmail'

import { type IAuthService } from '@vdi/auth-service'

export function sendUserDataToGa(authService: IAuthService) {
  const userGeraId = authService.userData.geraId
  const userId = authService.userData.id
  const userType = userTypeByEmail(authService.userData)

  const userInfoAnalytics = {
    event: 'VDIUser',
    user: {
      id: userId,
      geraId: userGeraId,
      userType,
      // @ts-expect-error - account deve ser adicionado ao UserData no @mfe-definition-types
      tenant: authService.userData?.account?.name ?? 'no-tenant'
    }
  }

  GtmTracker.init(userGeraId)
  GtmTracker.track({ user_id: userId })
  GtmTracker.track(userInfoAnalytics)
}
