import { type UserData } from '@vdi/auth-service'

export function userTypeByEmail(userData: UserData) {
  const domainFromUserEmail = userData.email.split('@')[1]

  if (
    domainFromUserEmail === 'grupoboticario.com.br' ||
    domainFromUserEmail === 'e-boticario.com.br' ||
    domainFromUserEmail === 'oboticario.com.br' ||
    domainFromUserEmail === 'eudora.com.br' ||
    domainFromUserEmail === 'dbm.com.br' ||
    domainFromUserEmail === 'grupoelo.com'
  ) {
    return 'internal'
  }

  return 'external'
}
